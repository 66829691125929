import React, { Component } from 'react'
import { withRouter/* , Link */ } from 'react-router-dom'
import ImageGallery from 'react-image-gallery'
import axios from 'axios'
import { isMobile, isTablet } from 'react-device-detect'
// import { CloudinaryContext, Transformation, Image } from 'cloudinary-react'
import { Icon } from 'semantic-ui-react'
import _ from 'lodash'

import { imgLinkFromPublicId, artCollectionURLForTag } from './utils'
import config from './config/default'

class Item extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: null,
      tag: props.match.params.tag,
      itemId: decodeURIComponent(props.match.params.item_id),
      wishlist: props.wishlist,
      isWishlist: props.isWishlist
    }
    this.handleAddToWishlist = props.handleAddToWishlist
    this.handleRemoveFromWishlist = props.handleRemoveFromWishlist
  }

  static getDerivedStateFromProps (props, state) {
    return {
      ...state,
      ...{
        tag: props.match.params.tag,
        itemId: decodeURIComponent(props.match.params.item_id),
        wishlist: props.wishlist,
        isWishlist: props.isWishlist
      }
    }
  }

  async fetchGallery () {
    // console.log('item fetchGallery')
    try {
      const res = await axios.get(artCollectionURLForTag(config.cloudId, this.state.tag))
      this._shouldRerender = true
      if (this.mounted) {
        this.setState({ gallery: res.data.resources })
      }
    } catch (e) {
      this.props.history.push('/404')
      console.log(e)
    }
  }

  componentDidMount () {
    this.mounted = true
    if (!this.props.isWishlist) this.fetchGallery()
  }

  _onSlide (currentIndex) {
    const isWishlist = this.state.isWishlist
    const list = isWishlist ? this.state.wishlist : this.state.gallery
    const item = list[currentIndex]
    // console.log(item)
    if (isWishlist) {
      this.props.history.push(`/wishlist/${encodeURIComponent(item.public_id)}`)
    } else {
      this.props.history.push(`/${this.props.match.params.tag}/${encodeURIComponent(item.public_id)}`)
    }
  }

  render () {
    const isWishlist = this.state.isWishlist
    const tag = this.state.tag
    if (!isWishlist && !this.state.gallery) {
      return <h3>loading...</h3>
    }
    const list = isWishlist ? this.state.wishlist : this.state.gallery
    const itemIndex = list.findIndex(it => it.public_id === this.state.itemId)
    if (itemIndex === -1 && isWishlist) {
      setTimeout(() => this.props.history.push('/wishlist'), 10)
      return <div />
    }
    const item = list[itemIndex]
    let width = false
    if (isMobile) width = 400
    if (isTablet) width = 800
    const images = list.map(i => (
      {
        original: imgLinkFromPublicId(config.cloudId, i.public_id, width),
        thumbnail: imgLinkFromPublicId(config.cloudId, i.public_id, 100)
      }
    ))
    let wishlistContent = (
      <div>
        {this.state.wishlist.find(d => d.public_id === item.public_id)
          ? (
            <Icon onClick={(event) => { event.preventDefault(); this.handleRemoveFromWishlist({ ...item, tag }) }} name='heart' />
            )
          : (
            <Icon onClick={(event) => { event.preventDefault(); this.handleAddToWishlist({ ...item, tag }) }} name='heart outline' />
            )}
      </div>
    )
    if (isWishlist) {
      wishlistContent = <div><Icon onClick={(event) => { event.preventDefault(); this.handleRemoveFromWishlist({ ...item }) }} name='heart' /></div>
    }
    return (
      <div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-10 item-header flex justify-end t-white'>
              {/* <Link className='t-black' to={`/gallery/${this.state.tag}`}>All {this.state.tag}</Link> */}
              {_.has(item, config.gallery.itemPricePath) && <span className='mr5'>€{_.get(item, config.gallery.itemPricePath)}</span>}
              {wishlistContent}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <ImageGallery
              items={images}
              showPlayButton={false}
              showBullets={false}
              lazyLoad
              startIndex={itemIndex}
              onSlide={this._onSlide.bind(this)}
              showFullscreenButton={false}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Item)

// <CloudinaryContext cloudName={config.cloudId} className='row item-view'>
//   {this.state.hasPrevious ? (
//     <Link
//       to={`${encodeURIComponent(this.state.previousItem.public_id)}`}
//       className='prev-link'
//     >
//       <svg className='icon'>
//         <use xlinkHref='#icon-chevron'></use>
//       </svg>
//     </Link>
//   ) : (
//     ''
//   )}
//   <div className='col-md-6'>
//     <Image className='card-img-top' publicId={this.state.item.public_id}>
//       <Transformation
//         crop='limit'
//         dpr='auto'
//         responsive={true}
//         responsive_placeholder='blank'
//       />
//     </Image>
//   </div>
//   <div className='col-md-6'>
//     <div>
//       {_.get(this.state.item, 'context.custom.tags')
//         && _.get(this.state.item, 'context.custom.tags')
//           .split(',')
//           .map(it => it.trim())
//           .map(tag => <Link to={`/${tag}`} className='badge badge-default' key={tag}>{tag}</Link>)
//       }
//     </div>
//     <div>
//       {_.has(this.state.item, config.gallery.itemPricePath) && <span className='ml5'>€{_.get(this.state.item, config.gallery.itemPricePath)}</span>}
//     </div>
//     <div className='pointer'>
//       {this.state.wishlist.find(d => d.public_id === this.state.item.public_id)
//       ? (
//           <Icon onClick={() => this.handleRemoveFromWishlist({ ...this.state.item, tag: this.state.tag })} name='heart' />
//       ) : (
//           <Icon onClick={() => this.handleAddToWishlist({ ...this.state.item, tag: this.state.tag })} name='heart outline' />
//       )}
//     </div>
//   </div>
//   {this.state.hasNext && this.state.nextItem ? (
//     <Link to={`${encodeURIComponent(this.state.nextItem.public_id)}`} className='next-link'>
//       <svg className='icon'>
//         <use xlinkHref='#icon-chevron'></use>
//       </svg>
//     </Link>
//   ) : (
//     ''
//   )}
// </CloudinaryContext>
