import React, { useContext, useRef } from 'react'
import {
  Flex,
  Button
} from '@chakra-ui/react'
import QuoteContext from './quote-context'

const WeekSynchrornizer = () => {
  const ref = useRef(null)
  const { isMobile, quote, updateAndReload } = useContext(QuoteContext)
  const { isRentalWeeksPerItem = false } = quote || {}

  const syncAllWeeks = () => {
    const value = +(ref?.current?.value ?? 1)
    for (const item of quote?.rows || []) {
      item.rentalWeeks = value
    }
    updateAndReload()
  }

  return (
    <Flex ml={isMobile ? '4' : '8'} mt={1} className='tw-print:tw-hidden'>
      <Button
        colorScheme='blue'
        disabled={!isRentalWeeksPerItem}
        onClick={syncAllWeeks}
      >
        Sync all weeks to
      </Button>
      <input
        ref={ref}
        className='tw-ml-1'
        type='number'
        defaultValue={1}
        min={0}
        disabled={!isRentalWeeksPerItem}
        style={{ width: '5ch' }}
      />
    </Flex>
  )
}

export default WeekSynchrornizer
