import React, { useContext, useEffect, useState, useRef } from 'react'
import * as _ from 'lodash'
import {
  GridItem,
  Input,
  // Select, used to select prices per day, week , month (see commented code bellow)
  Text,
  IconButton,
  Box,
  Textarea
} from '@chakra-ui/react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { imgLinkFromPublicId, getPriceAndRest } from '../utils'
import config from '../config/default'
import { BiEuro } from 'react-icons/bi'
import { AiOutlinePercentage } from 'react-icons/ai'
import QuoteContext from './quote-context'
import { RiDeleteBin6Line } from 'react-icons/ri'
import ImgInput from '../img-input'

// example renting a sofa  of 100 euros per week for 5 weeks: 100 (week 1) + 15*3 (week 2,3 amd 4) + 10 week5
// Week 1 (minimum proce 7 days) full price
// 2-4 weeks 15% of indicated price //
// week 5 10% of indicated price

const PRICE_PER_UNIT = {
  WEEK: 'week'
}

const DISCOUNT_UNIT = {
  PERCENTAGE: '%',
  EURO: '€'
}

const GridItemComp = ({ item, showDiscountColumn = false, rentalDays = 0 }) => {
  const { updateAndReload, quote, isMobile, isPrint } = useContext(QuoteContext)
  const { isRentalWeeksPerItem = false } = quote || {}
  const width = 80
  const height = Math.round(item.height / (item.width / width))
  const { price, rest } = getPriceAndRest(item)
  const [newPrice, setNewPrice] = useState(item.price || price || 0)
  const [pricePerUnit] = useState(item.pricePerUnit || rest.replace(/^(\s+)?\//gi, '').trim())
  // const tag = data.tag
  const pricePerUnits = Object.values(PRICE_PER_UNIT)
  if (!_.isEmpty(pricePerUnit) && !pricePerUnits.includes(pricePerUnit)) {
    pricePerUnits.push(pricePerUnit)
  }
  const [discountUnit, setDiscountUnit] = useState(item.discountUnit || '%')
  const [discount, setDiscount] = useState(item.discount || 0)
  const [quantity, setQuantity] = useState(item.quantity || 1)
  const [subTotal, setSubTotal] = useState(0)
  const [total, setTotal] = useState(item.total || 0)
  const [name, setName] = useState(item.name ?? (typeof item.public_id === 'string' ? item.public_id.replace(/\/[^/]+\/[^/]+$/, '') : ''))
  const [imgBase64, setImgBase64] = useState(item.imgBase64 || '')

  const updateDebounced = useRef(_.debounce((quote) => {
    // console.log('updateDebounced updateAndReload')
    updateAndReload(quote)
  }, 1000)).current

  const rentalWeeksTemp = Math.ceil(rentalDays / 7)

  useEffect(() => {
    const rentalWeeksTemp = Math.ceil(rentalDays / 7)
    const rentalWeeksLocal = isRentalWeeksPerItem ? item.rentalWeeks ?? rentalWeeksTemp : rentalWeeksTemp
    if (newPrice != null && pricePerUnit != null && rentalDays != null) {
      let subTotal = 0
      for (let i = 1; i <= rentalWeeksLocal; i++) {
        if (i === 1) {
          subTotal += +newPrice // full price for fisrt week
        }
        if (i > 1 && i <= 4) {
          subTotal += +newPrice * 0.15 // weeks 2 and 4 - 15 % of indicated price
        }
        if (i > 4) {
          subTotal += +newPrice * 0.1 // weeks 5 and more 10% of indicated price
        }
      }
      subTotal = subTotal * +quantity
      subTotal = +subTotal.toFixed(2)
      setSubTotal(subTotal)
      let total = subTotal
      if (showDiscountColumn) {
        if (discountUnit === DISCOUNT_UNIT.EURO) {
          total = subTotal - (discount || 0)
        } else total = subTotal - (discount ? subTotal * (discount / 100) : 0)
      }
      total = total && total.toFixed ? total.toFixed(2) : total
      setTotal(+total)
    }
  }, [ // eslint-disable-next-line
    discount,
    quantity,
    newPrice,
    pricePerUnit,
    rentalDays,
    showDiscountColumn,
    discountUnit,
    isRentalWeeksPerItem,
    item.rentalWeeks
  ])

  useEffect(() => {
    if (item) {
      let update = false
      if (item.price !== newPrice) {
        item.price = newPrice
        update = true
      }
      if (item.quantity !== quantity) {
        item.quantity = quantity
        update = true
      }
      if (item.discount !== discount) {
        item.discount = discount
        update = true
      }
      if (item.discountUnit !== discountUnit) {
        item.discountUnit = discountUnit
        update = true
      }
      if (item.pricePerUnit !== pricePerUnit) {
        item.pricePerUnit = pricePerUnit
        update = true
      }
      if (item.total !== total) {
        item.total = total
        update = true
      }
      if (item.name !== name) {
        item.name = name
        update = true
      }
      if (!item.public_id) {
        if (item.imgBase64 !== imgBase64) {
          item.imgBase64 = imgBase64
          update = true
        }
      }
      if (update) {
        updateDebounced.cancel()
        updateDebounced(quote)
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */ // eslint-disable-line
  }, [
    discountUnit,
    discount,
    quantity,
    newPrice,
    pricePerUnit,
    total,
    name,
    imgBase64
  ])
  /* eslint-enable react-hooks/exhaustive-deps */ // eslint-disable-line

  const deleteItem = () => {
    const index = quote.rows.indexOf(item)
    if (index > -1) {
      quote.rows.splice(index, 1)
      updateAndReload(quote)
    }
  }

  return (
    <>
      <GridItem
        colSpan={1}
        justifyItems='center'
        height='100%'
        className='tw-break-inside-avoid'
      >
        {item.public_id && (
          <LazyLoadImage
            alt={item.public_id}
            height={height}
            src={imgLinkFromPublicId(config.cloudId, item.public_id, 400)} // use normal <img> attributes as props
            width={width}
            visibleByDefault
          />
        )}
        {!item.public_id && (
          <ImgInput onChange={(d) => setImgBase64(d)} data={imgBase64} />
        )}
      </GridItem>

      <GridItem
        colSpan={1}
        display='flex'
        justifyContent='center'
        fontSize='xs'
      >
        <Textarea
          fontSize='xs'
          noOfLines={2}
          variant='unstyled'
          value={name}
          width='100%'
          onChange={(e) => setName(e.target.value)}
          height='100%'
          minWidth={isMobile && !isPrint ? '10ch' : '25ch'}
          resize='none'
        />
      </GridItem>

      <GridItem colSpan={1} display='flex' justifyContent='center'>
        <div width='100%'>
          <Input
            variant='unstyled'
            value={newPrice + DISCOUNT_UNIT.EURO}
            textAlign='center'
            width='50px'
            onChange={(e) =>
              setNewPrice(e.target.value.replace(DISCOUNT_UNIT.EURO, ''))}
          />
          {/*
          // <Select
          //   variant="unstyled"
          //   width="60px"
          //   value={pricePerUnit}
          //   className="select-quote"
          //   onChange={(e) => setPricePerUnit(e.target.value)}
          // >
          //   {pricePerUnits.map((key) => (
          //     <option key={key} value={key}>
          //       {key}
          //     </option>
          //   ))}
          // </Select>
          */}
        </div>
      </GridItem>

      {isRentalWeeksPerItem &&
        <GridItem colSpan={1} display='flex' justifyContent='center'>
          <input
            type='number'
            onChange={(e) => {
              let valueNumber = +(e?.target?.value ?? 0)
              valueNumber = valueNumber <= 0 ? 0 : valueNumber
              item.rentalWeeks = valueNumber
              updateAndReload()
            }}
            value={item.rentalWeeks ?? rentalWeeksTemp}
            min='0'
            style={{
              width: `${item.rentalWeeks ? Math.max(`${item.rentalWeeks}`.length, 2) : 2}ch`
            }}
          />
        </GridItem>}

      <GridItem colSpan={1} display='flex' justifyContent='center'>
        <input
          type='number'
          onChange={(e) => {
            const valueString = e.target.value
            return +valueString <= 0
              ? setQuantity(1)
              : setQuantity(+valueString)
          }}
          value={quantity}
          min='1'
          style={{
            width: `${quantity ? Math.max(`${quantity}`.length, 2) : 2}ch`
          }}
        />
      </GridItem>

      {showDiscountColumn && (
        <>
          <GridItem colSpan={1} justifyItems='center'>
            <Text fontSize='sm'>{subTotal}€</Text>
          </GridItem>

          <GridItem
            colSpan={1}
            display='flex'
            alignItems='center'
            justifyItems='center'
          >
            <input
              type='number'
              onChange={(e) => {
                let valueString = e.target.value
                if (isNaN(+valueString) || +valueString < 0) valueString = 0
                setDiscount(+valueString)
              }}
              value={discount}
              min={0}
              max={discountUnit === DISCOUNT_UNIT.PERCENTAGE ? 100 : Infinity}
              style={{
                width: `${discount ? Math.max(`${discount}`.length, 2) : 2}ch`
              }}
            />
            {discountUnit}
            <IconButton
              size='xs'
              className='tw-print:tw-hidden'
              borderRightRadius='0'
              colorScheme={
                discountUnit === DISCOUNT_UNIT.PERCENTAGE ? 'blue' : 'gray'
              }
              aria-label='percentage'
              onClick={() => setDiscountUnit(DISCOUNT_UNIT.PERCENTAGE)}
              icon={<AiOutlinePercentage className='tw-print:tw-hidden' />}
            />
            <IconButton
              size='xs'
              className='tw-print:tw-hidden'
              borderLeftRadius='0'
              colorScheme={
                discountUnit === DISCOUNT_UNIT.EURO ? 'blue' : 'gray'
              }
              aria-label='euro'
              onClick={() => setDiscountUnit(DISCOUNT_UNIT.EURO)}
              icon={<BiEuro className='tw-print:tw-hidden' />}
            />
          </GridItem>
        </>
      )}

      <GridItem
        colSpan={1}
        justifyContent='flex-end'
        display='flex'
        alignItems='center'
        fontSize='sm'
      >
        {total}
        {DISCOUNT_UNIT.EURO}
        <Box ml='1'>
          <RiDeleteBin6Line
            className='tw-print:tw-hidden'
            onClick={() => deleteItem(item)}
            cursor='pointer'
          />
        </Box>
      </GridItem>
    </>
  )
}

export default GridItemComp
