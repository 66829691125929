
import React, { useRef, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import * as _ from 'lodash'
import { Box, useBreakpointValue } from '@chakra-ui/react'

export const categorySortFn = (a, b) => {
  if (a.nbItems && b.nbItems) return a.name.localeCompare(b.name)
  if (a.nbItems && !b.nbItems) return -1
  if (!a.nbItems && b.nbItems) return 1
  if (!a.nbItems && !b.nbItems) return a.name.localeCompare(b.name)
}

export const SHOOTING_IN_PROGRESS_TXT = 'shooting in progress'
export const SIDEBAR_WIDTH = 220

export const CategoryMenuItem = (props) => {
  const ref = useRef(null)
  const [categoryOpen, setCategoryOpen] = useState(false)

  const { category, level = 0, activeCategory, isMobile, onLinkClickCallback = () => { } } = props
  const { nbItems } = category
  const classes = activeCategory === category.tag ? 't-b t--lowercase' : 't-fwu t--lowercase'
  const badgeLabel = nbItems && nbItems > 0 ? nbItems : ''
  const badge = badgeLabel ? '(' + badgeLabel + ')' : ''
  const textPaddings = isMobile ? ' pt10 pb10 ' : ' pt5 pb5 '

  const onClick = (e) => {
    // console.log(e.target, ref)
    if (e.target === ref) e.stopPropagation()
    setCategoryOpen(!categoryOpen)
  }

  const title = <span onClick={onClick} ref={ref} className={textPaddings}>{category.name} {badge}</span>

  if (_.isEmpty(category.subcategories) || !nbItems) {
    return (
      <Link onClick={(e) => onLinkClickCallback(e)} to={`/categories/${category.tag}`} href={`/categories/${category.tag}`} key={category.tag} className={classes + (!nbItems ? ' link-disabled' : '') + textPaddings}>
        {category.name} {badge}
      </Link>
    )
  } else {
    let isFirst = true
    category.subcategories.sort(categorySortFn)
    return (
      <div className='dropdown-submenu t--lowercase gallery-navbar-link pointer-only' title={title} key={category.tag}>
        {title}
        {categoryOpen &&
          <div className='dropdown-submenu-wrapper ml5'>
            <Link to={`/categories/${category.tag}`} href={`/categories/${category.tag}`} key={category.tag} className={textPaddings + 't-fwu'} onClick={(e) => onLinkClickCallback(e)}>
              All {category.name} {badge}
            </Link>
            {category.subcategories.map(subcategory => {
              const content = [<CategoryMenuItem level={level + 1} key={subcategory.tag} category={subcategory} activeCategory={activeCategory} isMobile={isMobile} onLinkClickCallback={onLinkClickCallback} />]
              if (!subcategory.nbItems && isFirst) {
                isFirst = false
                content.unshift(
                  <Link to={`/categories/${category.tag}`} key={category.tag + '_comming_soon'} className={textPaddings + 'link-disabled comming-soon t-fwu'}>
                    {SHOOTING_IN_PROGRESS_TXT}
                  </Link>)
              }
              return content
            })}
          </div>}
      </div>
    )
  }
}

const _CategoryMenu = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { categories, onLinkClickCallback } = props
  const { tag } = props.match.params
  const textPaddings = isMobile ? ' pt10 pb10 ' : ' pt5 pb5 '
  let isFirst = true
  return (
    <div className='flex justify-between tw-print:tw-hidden'>
      <div className='desktop-menu pt5 tw-print:tw-hidden'>
        <div className='link-dropdown'>
          {categories.map(it => {
            const content = [<CategoryMenuItem className='dropdown-submenu t--lowercase gallery-navbar-link' onLinkClickCallback={onLinkClickCallback} isMobile={isMobile} category={it} key={it.tag} activeCategory={tag} />]
            if (!it.nbItems && isFirst) {
              isFirst = false
              content.unshift(
                <div as={Link} to={`/categories/${it.tag}`} key={it.tag + '_comming_soon'} className={textPaddings + 'link-disabled comming-soon t-fwu'}>
                  {SHOOTING_IN_PROGRESS_TXT}
                </div>)
            }
            return content
          })}
        </div>
      </div>
    </div>
  )
}

export const CategoryMenu = withRouter(_CategoryMenu)

export const Sidebar = (props) => {
  const isHidden = useBreakpointValue({ base: true, md: false })
  const { categories } = props
  categories.sort(categorySortFn)

  return (
    <Box pr='1rem' display={isHidden ? 'none' : 'block'} w={`${SIDEBAR_WIDTH}px`} className={'tw-overflow-auto tw-h-screen ' + (props.className ?? '')}>
      <CategoryMenu categories={categories} />
    </Box>
  )
}

export default withRouter(Sidebar)
