
import React, { useEffect, useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import img from './assets/B6_banner.svg'
import imgMobile from './img/b6-fav.ico'
import { useBreakpointValue } from '@chakra-ui/media-query'
import { IconButton } from '@chakra-ui/button'
import { IoIosMenu } from 'react-icons/io'
import { useDisclosure, Drawer, DrawerBody, DrawerCloseButton, DrawerFooter, DrawerOverlay, DrawerContent, DrawerHeader, Flex } from '@chakra-ui/react'

import config from './config/default'
import { CategoryMenu } from './sidebar'

const getBreadcrumbUrl = (item, idx, allItems) => {
  if (item === 'categories') {
    return '/'
  } else if (idx === 0) {
    return `/${item}`
  } else if (idx === 1) {
    return `/${allItems[0]}/${item}`
  } else if (idx === 2) {
    return `/${allItems[0]}/${allItems[0]}:${item}`
  }
}

const NavbarLinks = (props) => {
  const { wishlist, match, isMobile, onLinkClickCallback = () => { } } = props
  return (
    <div className='desktop-menu pt5 tw-print:tw-hidden'>
      {!isMobile &&
        <Link onClick={onLinkClickCallback} as={Link} className={'pl0 t--lowercase ' + (match.path.match(/\/[^a-z0-9]?(.*)?/i) ? 'active-link' : 't-b')} to='/' href='/'>
          Home
        </Link>}
      <Link onClick={onLinkClickCallback} as={Link} className={`${isMobile ? 'pl0' : 'with-separator'} t--lowercase ` + (match.path.startsWith('/wishlist') ? 'active-link' : 't-b')} to='/wishlist' href='/wishlist'>
        Wishlist {wishlist.length > 0 ? `(${wishlist.length})` : ''}
      </Link>
      <Link onClick={onLinkClickCallback} as={Link} className={`${isMobile ? '' : 'with-separator'} t--lowercase ` + (match.path.startsWith('/contact') ? 'active-link' : 't-b')} to='/contact' href='/contact'>Contact</Link>
    </div>
  )
}

const NavbarSocialMediaIcons = (props) => {
  const { crumbs, isMobile } = props
  return (
    <div className={`flex ${isMobile ? 'pt10' : 'ml10'} align-center pt5`}>
      {!isMobile && crumbs.length > 0 && crumbs.map((c, idx, arr) => <Link key={`${c}-${idx}`} className='breadcrumb-item hide-max-sm' to={getBreadcrumbUrl(c, idx, arr)}>{c}</Link>)}
      <a className={`t--lowercase mr0 pr0 ${isMobile ? ' ' : 'ml10'}`} href={config.contact.instagram} target='_blank' rel='noopener noreferrer'>
        <span className='icofont-before icofont-instagram icofont-nm pl1' />
      </a>
      <a className='t--lowercase pl5 mr0 pr0' href={config.contact.facebook} target='_blank' rel='noopener noreferrer'>
        <span className='icofont-before icofont-facebook icofont-nm' />
      </a>
      <a className='t--lowercase pl5 mr0 pr0' href={config.contact.pinterest} target='_blank' rel='noopener noreferrer'>
        <span className='icofont-before icofont-pinterest icofont-nm' />
      </a>
    </div>
  )
}

export const Navbar = (props) => {
  const {
    wishlist,
    match,
    categories
  } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const padding = 1
  const [isPrint, setIsPrint] = useState(false)
  // const [show, setShow] = useState(true)
  // const [ignoreNextHandleScroll, setIgnoreNextHandleScroll] = useState(false)
  // const [scrollPos, setScrollPos] = useState(document.body.getBoundingClientRect().top)

  useEffect(() => {
    // const handleScroll = () => {
    //   const currentScrollTop = document.body.getBoundingClientRect().top

    //   if (ignoreNextHandleScroll) {
    //     setIgnoreNextHandleScroll(false)
    //     setScrollPos(currentScrollTop)
    //   } else if (show && currentScrollTop < scrollPos && ((scrollPos - currentScrollTop) > 30 && currentScrollTop < -300)) {
    //     // Set the state of hidden depending on scroll position
    //     // We only change the state if it needs to be changed
    //     setShow(false)
    //     setIgnoreNextHandleScroll(true)
    //     setScrollPos(currentScrollTop)
    //   } else if (!show && currentScrollTop > scrollPos && ((currentScrollTop - scrollPos) > 30 || currentScrollTop === 0)) {
    //     setShow(true)
    //     setIgnoreNextHandleScroll(true)
    //     setScrollPos(currentScrollTop)
    //   } else {
    //     setScrollPos(currentScrollTop)
    //   }
    // }
    const beforePrintFn = () => setIsPrint(true)
    const afterPrintFn = () => setIsPrint(false)
    window.addEventListener('beforeprint', beforePrintFn)
    window.addEventListener('afterprint', afterPrintFn)
    // window.addEventListener('scroll', handleScroll)
    return () => {
      // window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('beforeprint', beforePrintFn)
      window.removeEventListener('afterprint', afterPrintFn)
    }
  }, [])

  const style = {
    paddingLeft: padding + 'rem',
    paddingRight: padding + 'rem'
  }
  if (isMobile && !isPrint) {
    return (
      <Flex justifyContent='space-between' alignItems='center' px='1rem' py='0.75rem'>
        <Link to='/' style={{ display: 'block' }}>
          <img src={imgMobile} alt='b6' style={{ height: '30px' }} />
        </Link>
        <IconButton icon={<IoIosMenu fontSize='30' />} bgColor='white' onClick={onOpen} />
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent className='navbar_wrap'>
            <DrawerHeader>
              <DrawerCloseButton />
            </DrawerHeader>

            <DrawerBody overflowY='auto'>
              <CategoryMenu categories={categories} onLinkClickCallback={onClose} />
            </DrawerBody>

            <DrawerFooter flexDirection='column' alignItems='left'>
              <hr className='pb10' />
              <NavbarLinks wishlist={wishlist} match={match} isMobile={isMobile} onLinkClickCallback={onClose} />
              <NavbarSocialMediaIcons crumbs={[]} isMobile={isMobile} />
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Flex>
    )
  } else {
    let crumbs = []
    if (match && match.url) {
      crumbs = match.url.split('/').filter(i => i).map(i => i.split(':')).flatMap(i => i)
      if (crumbs[0] !== 'categories') crumbs = []
    }

    return (
      <div className='navbar_wrap mb15 tw-print:tw-mb-10 tw-print:tw-max-w-[560px] tw-print:tw-overflow-hidden tw-print:tw-mx-auto tw-print:tw-px-0' style={style}>
        <div className='logo'>
          <Link to='/'>
            <img src={img} alt='b6slug' />
          </Link>
        </div>
        <div className='flex justify-between tw-print:tw-hidden'>
          <NavbarLinks wishlist={wishlist} match={match} />
          <NavbarSocialMediaIcons crumbs={crumbs} isMobile={isMobile} />
        </div>
      </div>
    )
  }
}

export default withRouter(Navbar)
