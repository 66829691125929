import React, { useContext } from 'react'
import {
  Text,
  Switch,
  FormControl,
  FormLabel
} from '@chakra-ui/react'

import { QuoteContext } from './quote-context'

const Options = () => {
  const {
    quote,
    updateAndReload,
    isMobile
  } = useContext(QuoteContext)

  const {
    showDiscountColumn = false,
    showDiscountRow = false,
    showTitleRow = false,
    isRentalWeeksPerItem = false
  } = quote || {}

  const setValue = (value, key) => {
    if (quote) {
      quote[key] = value
      updateAndReload()
    }
  }

  return (
    <FormControl
      display='grid'
      gridTemplateColumns='auto auto'
      alignItems='center'
      maxWidth='250px'
      ml={isMobile ? '4' : '8'}
      className='tw-print:hidden'
    >
      <FormLabel
        htmlFor='set-rental-weeks-per-item'
        mb='0'
        className='tw-print:tw-hidden'
      >
        <Text className='tw-print:tw-hidden'>Set rental weeks per item</Text>
      </FormLabel>
      <Switch
        className='tw-print:tw-hidden'
        id='set-rental-weeks-per-item'
        isChecked={isRentalWeeksPerItem}
        onChange={() => setValue(!isRentalWeeksPerItem, 'isRentalWeeksPerItem')}
      />
      <FormLabel
        htmlFor='show-discount-column'
        mb='0'
        className='tw-print:tw-hidden'
      >
        <Text className='tw-print:tw-hidden'>Show discount column?</Text>
      </FormLabel>
      <Switch
        className='tw-print:tw-hidden'
        id='show-discount-column'
        isChecked={showDiscountColumn}
        onChange={() => setValue(!showDiscountColumn, 'showDiscountColumn')}
      />
      <FormLabel
        htmlFor='show-discount-row'
        mb='0'
        className='tw-print:tw-hidden'
      >
        <Text className='tw-print:tw-hidden'>Show discount row?</Text>
      </FormLabel>
      <Switch
        className='tw-print:tw-hidden'
        id='show-discount-row'
        isChecked={showDiscountRow}
        onChange={() => setValue(!showDiscountRow, 'showDiscountRow')}
      />
      <FormLabel
        htmlFor='show-header-row'
        mb='0'
        className='tw-print:tw-hidden'
      >
        <Text className='tw-print:tw-hidden'>Show header row?</Text>
      </FormLabel>
      <Switch
        className='tw-print:tw-hidden'
        id='show-header-row'
        isChecked={showTitleRow}
        onChange={() => setValue(!showTitleRow, 'showTitleRow')}
      />
    </FormControl>
  )
}

export default Options
