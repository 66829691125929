import { useState, useEffect } from 'react'
import setupIndexedDB, { useIndexedDBStore } from 'use-indexeddb'

// Hook
export function useLocalStorage (key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }
  return [storedValue, setValue]
}

export function useQuotesIndexedDBStorage () {
  const [dbInitialized, setDbInitialized] = useState(false)
  // Database Configuration
  const idbConfig = {
    databaseName: 'quotes-db',
    version: 1,
    stores: [
      {
        name: 'quotes',
        id: { keyPath: 'id', autoIncrement: true },
        indices: [
          { name: 'wishlistId', keyPath: 'wishlistId', options: { unique: false } },
          { name: 'name', keyPath: 'name', options: { unique: false } },
          { name: 'createdAt', keyPath: 'createdAt', options: { unique: false } }
        ]
      }
    ]
  }

  useEffect(() => {
    setupIndexedDB(idbConfig)
      .then(() => {
        console.log('success')
        setDbInitialized(true)
      })
      .catch(e => console.error('error / unsupported', e))
  })

  const {
    add, getAll, deleteByID, getByID, update
  } = useIndexedDBStore('quotes')

  return { dbInitialized, add, getAll, deleteByID, getByID, update }
}
