import React, { useContext, useEffect, useState } from 'react'
import { GridItem, Input, Text } from '@chakra-ui/react'
import QuoteContext from './quote-context'

const millisecondsPerDay = 24 * 60 * 60 * 1000
function countDays (startDate, endDate) {
  return Math.floor((endDate - startDate) / millisecondsPerDay) + 1
}

const QuoteRowRentalPeriod = ({ showDiscountColumn }) => {
  const { quote, updateAndReload } = useContext(QuoteContext)
  const defaultDateStr = (new Date()).toISOString().replace(/T.*/i, '')
  const [startDate, setStartDate] = useState(quote && quote.startDate ? quote.startDate : defaultDateStr)
  const [endDate, setEndDate] = useState(quote && quote.endDate ? quote.endDate : defaultDateStr)
  const [rentalDays, setRentalDays] = useState(
    quote ? quote.rentalDays || 0 : 0
  )

  /* eslint-disable react-hooks/exhaustive-deps */ // eslint-disable-line
  useEffect(() => {
    if (startDate != null && endDate != null) {
      const start = new Date(startDate)
      const end = new Date(endDate)
      if (+start > +end) setEndDate(startDate)
    }
  }, [startDate])

  useEffect(() => {
    if (startDate != null && endDate != null) {
      const start = new Date(startDate)
      const end = new Date(endDate)
      if (+start > +end) setStartDate(endDate)
    }
  }, [endDate])

  useEffect(() => {
    if (startDate != null && endDate != null) {
      const start = new Date(startDate)
      const end = new Date(endDate)
      const days = countDays(start, end)
      setRentalDays(days)
      quote.rentalDays = days
      updateAndReload()
    }
    quote.startDate = startDate
    quote.endDate = endDate
  }, [startDate, endDate])
  /* eslint-enable react-hooks/exhaustive-deps */ // eslint-disable-line

  if (quote == null) return <></>

  const {
    isRentalWeeksPerItem = false
  } = quote

  let colspan = 4
  if (showDiscountColumn) colspan += 2
  if (isRentalWeeksPerItem) colspan += 1

  return (
    <>
      <GridItem colSpan={1}>
        <Text fontSize='sm' as='b'>
          Rental Period:
        </Text>
      </GridItem>
      <GridItem
        colSpan={colspan}
        display='flex'
        alignItems='center'
      >
        <Input
          className='tw-print:tw-p-0'
          variant='unstyled'
          placeholder='Select Date'
          size='md'
          type='date'
          width='100px'
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <Text fontSize='sm' as='b' mx='1'>
          -
        </Text>
        <Input
          variant='unstyled'
          placeholder='Select Date'
          size='md'
          type='date'
          width='100px'
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <Text fontSize='sm' as='b'>
          Rental Weeks:
        </Text>
      </GridItem>
      <GridItem
        colSpan={colspan}
        display='flex'
        alignItems='center'
      >
        {Math.ceil(rentalDays / 7)}
      </GridItem>
    </>
  )
}

export default QuoteRowRentalPeriod
