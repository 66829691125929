import React, { useContext } from 'react'
import {
  GridItem
} from '@chakra-ui/react'

import { QuoteContext } from './quote-context'

const GridItemHeaders = ({ showDiscountColumn = false, isRentalWeeksPerItem = false }) => {
  const {
    isMobile,
    isPrint
  } = useContext(QuoteContext)
  const fontSize = isMobile && !isPrint ? 'sm' : 'md'
  return (
    <>
      <GridItem
        colSpan={1}
        justifyContent='center'
        display='flex'
        fontSize='xs'
      />

      <GridItem
        colSpan={1}
        justifyContent='center'
        display='flex'
        fontSize={fontSize}
      >
        Item
      </GridItem>

      <GridItem
        colSpan={1}
        justifyContent='center'
        display='flex'
        fontSize={fontSize}
        whiteSpace='break-spaces'
      >
        {isMobile && !isPrint ? 'Price / week' : 'Price/week'}
      </GridItem>

      {isRentalWeeksPerItem &&
        <GridItem
          colSpan={1}
          justifyContent='center'
          display='flex'
          fontSize={fontSize}
        >
          Weeks
        </GridItem>}

      <GridItem
        colSpan={1}
        justifyContent='center'
        display='flex'
        fontSize={fontSize}
      >
        Qty
      </GridItem>

      {showDiscountColumn && (
        <>
          <GridItem
            colSpan={1}
            justifyContent='center'
            display='flex'
            fontSize={fontSize}
          >
            SubTotal
          </GridItem>
          <GridItem
            colSpan={1}
            justifyContent='center'
            display='flex'
            fontSize={fontSize}
          >
            Discount
          </GridItem>
        </>
      )}

      <GridItem
        colSpan={1}
        justifyContent='center'
        display='flex'
        fontSize='md'
      >
        Total
      </GridItem>
    </>
  )
}

export default GridItemHeaders
