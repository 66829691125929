import React, { useEffect, useState } from 'react'
import {
  AspectRatio,
  Box,
  Container,
  Heading,
  Input,
  Stack,
  Text,
  Img
} from '@chakra-ui/react'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { resizeImg } from './utils'

/* global FileReader */
export default function ImgInput ({ onChange, data, placeholder }) {
  const [opacityImg, setOpacityImg] = useState(1)
  const [dataBase64, setDataBase64] = useState(data || '')
  const [disabled, setDisabled] = useState(dataBase64 == null || dataBase64.length === 0)

  useEffect(() => {
    if (typeof onChange === 'function' && dataBase64 && dataBase64.length > 0 && data !== dataBase64) onChange(dataBase64)
    setDisabled(dataBase64 == null || dataBase64.length === 0)
  }, [dataBase64, onChange]) // eslint-disable-line

  useEffect(() => {
    setDataBase64((preval) => {
      if (data != null && data !== preval) return data
      return preval
    })
  }, [data])

  const handleFile = (e) => {
    if (e && e.target && e.target.files && e.target.files.length != null) {
      const imageFile = e.target.files[0]
      if (imageFile == null) return
      const reader = new FileReader()
      reader.onload = function () {
        resizeImg(String(reader.result), 400, 300)
          .then((resizedImg) => setDataBase64(resizedImg))
      }
      reader.readAsDataURL(imageFile)
    }
  }

  const deleteAvatar = (e) => {
    console.log('deleteAvatar')
    if (disabled) return
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setDataBase64('')
    if (typeof onChange === 'function') {
      onChange('')
    }
  }

  return (
    <Container>
      <AspectRatio maxW={300}>
        <Box>
          <Box position='relative' height='100%' width='100%' overflow='hidden'>
            <Box
              position='absolute'
              top='0'
              left='0'
              height='100%'
              width='100%'
              display='flex'
              flexDirection='column'
              cursor='pointer'
              borderColor='gray.300'
              borderStyle='dashed'
              borderWidth='2px'
              shadow='sm'
              role='group'
              transition='all 150ms ease-in-out'
              _hover={{
                shadow: 'md'
              }}
              initial='rest'
              animate='rest'
              overflow='hidden'
            >
              <Stack p='8' textAlign='center' spacing='1' alignItems='center' height='100%' width='100%' className='print:hidden'>
                <Heading fontSize='lg' color='gray.700' fontWeight='bold'>
                  Drop images here
                </Heading>
                <Text fontWeight='light'>or click to upload</Text>
              </Stack>
            </Box>
            {dataBase64 && <Img src={dataBase64} name={placeholder} position='absolute' bottom='0' left='0' height='100%' width='100%' opacity={opacityImg} />}
            <Input
              type='file'
              height='100%'
              width='100%'
              position='absolute'
              p='0'
              top='0'
              left='0'
              opacity='0'
              aria-hidden='true'
              accept='image/*'
              cursor='pointer'
              overflow='hidden'
              onPointerEnter={() => setOpacityImg(0.2)}
              onPointerLeave={() => setOpacityImg(1)}
              onChange={handleFile}
            />
          </Box>
          {!disabled &&
            <Box className='tw-print:tw-hidden' position='absolute' right='5' bottom='5' zIndex={100} onPointerEnter={() => setOpacityImg(0.2)} onPointerLeave={() => setOpacityImg(1)}>
              <RiDeleteBin6Line color='var(--main-blue)' cursor='pointer' onClick={deleteAvatar} className='tw-print:tw-hidden' />
            </Box>}
        </Box>
      </AspectRatio>
    </Container>
  )
}
