import React, { useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
// import * as _ from 'lodash'
import {
  Grid,
  GridItem,
  Button
} from '@chakra-ui/react'
import { Icon } from 'semantic-ui-react'
import QuoteContext, { QuoteContextProvider } from './quote-context'
import GridItemComp from './row-item'
import QuoteTotalRows from './total-rows'
import QuoteRowRentalPeriod from './rental-period-rows'
import GridHeaderRow from './header-row'
import config from '../config/default'
import QuoteDate from './quote-date'
import Options from './options'
import GridItemHeaders from './grid-headers'
import WeekSynchrornizer from './week-synchornizer'

const { address, contact } = config
// import { Contact } from '../contact'

let timeoutId = null
const Quote = () => {
  const {
    quote, updateAndReload, addRow, addHeaderRow, addItemsFromWishlist, isMobile,
    isPrint
  } = useContext(QuoteContext)
  const [title, _setTitle] = useState(quote ? quote.title : 'Quote')

  const {
    showDiscountColumn = false,
    showTitleRow = false,
    isRentalWeeksPerItem = false
  } = quote || {}

  const setValue = (value, key) => {
    if (quote) quote[key] = value
    updateAndReload()
  }

  const setTitle = (value) => {
    clearTimeout(timeoutId)
    _setTitle(value)
    timeoutId = setTimeout(() => setValue(value, 'title'), 500)
  }

  if (quote == null) return <></>

  let templateColumns = isMobile && !isPrint ? '80px 80px min-content 80px min-content' : '80px 200px min-content 80px min-content'
  if (isRentalWeeksPerItem) templateColumns += ' min-content'
  if (showDiscountColumn) templateColumns += ' min-content min-content'

  let colspan = 5
  if (showDiscountColumn) colspan += 2
  if (isRentalWeeksPerItem) colspan += 1

  return (
    <>
      <Options />
      <Button
        colorScheme='blue'
        onClick={() => addHeaderRow()}
        className='tw-print:tw-hidden'
        ml={isMobile ? '4' : '8'}
      >
        Add header row
      </Button>
      <WeekSynchrornizer />
      <table className='tw-max-w[20cm] tw-m-auto'>
        <tbody>
          <tr className='tw-w-full'>
            <td className='tw-w-full'>
              <Grid
                templateColumns={templateColumns}
                gap='1'
                rowGap='4'
                className={`tw-p-${isMobile ? '1' : '8'} tw-max-w[20cm]`}
                alignItems='center'
                justifyContent='center'
              >
                {showTitleRow && (
                  <GridItem
                    colSpan={colspan}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    rowGap='0'
                  >
                    <input
                      type='text'
                      value={title || 'Quote'}
                      className='tw-text-2xl tw-text-center tw-max-w-prose tw-truncate tw-underline'
                      style={{ width: `${Math.max((title || '').length, 50)}ch` }}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </GridItem>
                )}

                {(quote.headerRows || []).map((h) => (
                  <GridHeaderRow
                    key={h.id}
                    showDiscountColumn={showDiscountColumn}
                    headerRow={h}
                  />
                ))}

                <QuoteDate showDiscountColumn={showDiscountColumn} />
                {!isRentalWeeksPerItem && <QuoteRowRentalPeriod showDiscountColumn={showDiscountColumn} />}

                <GridItemHeaders showDiscountColumn={showDiscountColumn} isRentalWeeksPerItem={isRentalWeeksPerItem} />

                {Array.isArray(quote.rows) &&
                  quote.rows.map((data, idx) => (
                    <GridItemComp
                      key={data.public_id || data.id || Date.now() + idx}
                      item={data}
                      showDiscountColumn={showDiscountColumn}
                      rentalDays={quote.rentalDays || 0}
                    />
                  ))}

                <GridItem colSpan={colspan} display='flex'>
                  <Button
                    colorScheme='blue'
                    onClick={() => addRow()}
                    className='tw-print:tw-hidden'
                  >
                    Add item
                  </Button>
                  <Button
                    colorScheme='blue'
                    onClick={() => addItemsFromWishlist()}
                    className='tw-print:tw-hidden'
                    ml='1'
                  >
                    Add items from wishlist
                  </Button>
                </GridItem>

                <QuoteTotalRows showDiscountColumn={showDiscountColumn} />
              </Grid>
            </td>
          </tr>
        </tbody>
        <tfoot className='tw-w-full'>
          <tr>
            <td className='tw-w-full tw-text-center tw-flex align-center tw-block justify-center tw-hidden tw-print:tw-flex'>
              <div className='tw-text-left'>
                {address.street}
                <br />
                {address.zip} {address.city}, {address.country}
              </div>
              <div className='tw-text-left tw-mx-4'>
                <Icon name='phone' />: {contact.phone}
                <br />
                <Icon name='envelope' />: {contact.email}
              </div>
              <div className='tw-text-left'>
                IBAN: {contact.bankaccountNumber}
                <br />
                VAT: {contact.vat}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  )
}

const QuoteWithContext = (props) => {
  return (
    <QuoteContextProvider {...props}>
      <Quote {...props} />
    </QuoteContextProvider>
  )
}

export default withRouter(QuoteWithContext)
