// function grabItems(el, cat) {
//     return JSON.stringify(Array.from(el.querySelectorAll('li')).map(li => { const name = li.textContent.replace('→ ', ''); return {name: name, tag: `${cat}:${name.toLowerCase().replace(/\s(-\s)?/gi, '-')}`};}), null, 2);
// }

// import { truncate } from "lodash";
// import { findRenderedDOMComponentWithTag } from "react-dom/test-utils";

export default [
  {
    name: 'Seating',
    tag: 'seating',
    pingpong: true,
    subcategories: [
      {
        name: 'Chair - Rocking',
        tag: 'seating:chair-rocking'
      },
      {
        name: 'Chair - Dining',
        tag: 'seating:chair-dining'
      },
      {
        name: 'Chair - Office',
        tag: 'seating:chair-office'
      },
      {
        name: 'Chair - Professional',
        tag: 'seating:chair-professional'
      },
      {
        name: 'Sofa and Couch',
        tag: 'seating:sofa-and-couch'
      },
      {
        name: 'Bench',
        tag: 'seating:bench'
      },
      {
        name: 'Chair - Lounge',
        tag: 'seating:chair-lounge'
      },
      {
        name: 'Stools',
        tag: 'seating:stools'
      },
      {
        name: 'Chair - Folding',
        tag: 'seating:chair-folding'
      },
      {
        name: 'Chair - Kids',
        tag: 'seating:chair-kids'
      },
      {
        name: 'Stools - Bar',
        tag: 'seating:stools-bar'
      }
    ]
  },
  {
    name: 'Kitchen',
    tag: 'kitchen',
    pingpong: true,
    subcategories: [
      {
        name: 'Furniture',
        tag: 'kitchen:furniture'
      },
      {
        name: 'Food',
        tag: 'kitchen:food'
      },
      {
        name: 'Trays',
        tag: 'kitchen:trays'
      },
      {
        name: 'Kitchen - Cookware',
        tag: 'kitchen:kitchen-cookware'
      },
      {
        name: 'Kitchen - Dishes and cutlery',
        tag: 'kitchen:dishes-and-cutlery'
      }
    ]
  },
  {
    name: 'Bathroom',
    tag: 'bathroom',
    pingpong: false,
    subcategories: [
      {
        name: 'Accessories',
        tag: 'bathroom:accessories'
      },
      {
        name: 'Furniture',
        tag: 'bathroom:furniture'
      }
    ]
  },
  {
    name: 'Tables',
    tag: 'tables',
    pingpong: true,
    subcategories: [
      {
        name: 'Desks',
        tag: 'tables:desks'
      },
      {
        name: 'Dining',
        tag: 'tables:dining'
      },
      {
        name: 'Coffee',
        tag: 'tables:coffee'
      },
      {
        name: 'Kitchen',
        tag: 'tables:kitchen'
      },
      {
        name: 'Console',
        tag: 'tables:console'
      },
      {
        name: 'Office',
        tag: 'tables:office'
      },
      {
        name: 'Nightstands',
        tag: 'tables:nightstands'
      }
    ]
  },
  {
    name: 'Furniture',
    tag: 'standing-furniture-case-goods',
    pingpong: true,
    subcategories: [
      {
        name: 'Chest of drawers',
        tag: 'standing-furniture-case-goods:chest-of-drawers'
      },
      {
        name: 'Standing mirrors',
        tag: 'standing-furniture-case-goods:standing-mirrors'
      },
      {
        name: 'Screens & claustras',
        tag: 'standing-furniture-case-goods:screens-claustras'
      },
      {
        name: 'Beds & headboards',
        tag: 'standing-furniture-case-goods:beds-headboards'
      },
      {
        name: 'Standing Cabinets',
        tag: 'standing-furniture-case-goods:standing-cabinets'
      },
      {
        name: 'Standing Armoires',
        tag: 'standing-furniture-case-goods:standing-armoires'
      },
      {
        name: 'Secretaries',
        tag: 'standing-furniture-case-goods:secretaries'
      },
      {
        name: 'Standing Kitchen Cabinets',
        tag: 'standing-furniture-case-goods:standing-kitchen-cabinets'
      },
      {
        name: 'Standing Bookshelves',
        tag: 'standing-furniture-case-goods:standing-bookshelves'
      },
      {
        name: 'Coat Rack',
        tag: 'standing-furniture-case-goods:coat-rack'
      },
      {
        name: 'Floor Racks',
        tag: 'standing-furniture-case-goods:floor-racks'
      },
      {
        name: 'Furniture - Miscellaneous',
        tag: 'standing-furniture-case-goods:furniture-miscellaneous'
      }
    ]
  },
  {
    name: 'School',
    tag: 'school',
    pingpong: true,
    subcategories: [
      {
        name: 'Posters',
        tag: 'school:posters'
      },
      {
        name: 'Drawings',
        tag: 'school:drawings'
      },
      {
        name: 'School Furniture',
        tag: 'school:furniture'
      },
      {
        name: 'School Bags',
        tag: 'school:bags'
      },
      {
        name: 'Documents and panels',
        tag: 'school:documents and panels'
      }
    ]
  },
  {
    name: 'Lighting',
    tag: 'lighting',
    pingpong: true,
    subcategories: [
      {
        name: 'Ornated lamps',
        tag: 'lighting:ornated-lamps'
      },
      {
        name: 'Hanging pendants',
        tag: 'lighting:hanging-pendants'
      },
      {
        name: 'Street lamps',
        tag: 'lighting:street-lamps'
      },
      {
        name: 'Lamp - Shades',
        tag: 'lighting:lamp-shades'
      },
      {
        name: 'Lamps - Table',
        tag: 'lighting:lamps-table'
      },
      {
        name: 'Sconces/Wall Brackets',
        tag: 'lighting:sconces-wall-brackets'
      },
      {
        name: 'Lamps - Floor',
        tag: 'lighting:lamps-floor'
      },
      {
        name: 'Lamps - Desk',
        tag: 'lighting:lamps-desk'
      },
      {
        name: 'Lamps - Kids',
        tag: 'lighting:lamps-kids'
      },
      {
        name: 'Industrial/Commercial Lighting',
        tag: 'lighting:industrial-commercial-lighting'
      },
      {
        name: 'Signs - Standing',
        tag: 'lighting:signs-standing'
      }
    ]
  },
  {
    name: 'Art',
    tag: 'art',
    pingpong: true,
    subcategories: [
      {
        name: 'Paintings',
        tag: 'art:paintings'
      },
      {
        name: 'Frames',
        tag: 'art:frames'
      },
      {
        name: 'Abstract paintings',
        tag: 'art:abstract-paintings'
      },
      {
        name: 'Art Related - Miscellaneous',
        tag: 'art:art-related-miscellaneous'
      },
      {
        name: 'Animals',
        tag: 'art:animals'
      },
      {
        name: 'Sculpture',
        tag: 'art:sculpture'
      },
      {
        name: 'Posters',
        tag: 'art:posters'
      },
      {
        name: 'Tribal',
        tag: 'art:tribal'
      },
      {
        name: 'Photographs',
        tag: 'art:photographs'
      }
    ]
  },
  {
    name: 'Textiles',
    tag: 'rugs',
    pingpong: true,
    subcategories: [
      {
        name: 'Animal skins',
        tag: 'textiles:animal-skins'
      },
      {
        name: 'Curtains',
        tag: 'textiles:curtains'
      },
      {
        name: 'Cushions',
        tag: 'textiles:cushions'
      },
      {
        name: 'Bedding',
        tag: 'textiles:bedding'
      },
      {
        name: 'Window blinds',
        tag: 'textiles:window-blinds'
      },
      {
        name: 'Rugs',
        tag: 'textiles:rugs'
      }
    ]
  },
  {
    name: 'Copyright Free',
    tag: 'copyright-free',
    pingpong: false,
    subcategories: []
  },
  {
    name: 'Accessories',
    tag: 'accessories',
    pingpong: true,
    subcategories: [
      {
        name: 'Signboards',
        tag: 'accessories:sign-boards'
      },
      {
        name: 'Fireplace accessories',
        tag: 'accessories:fireplace-accessories'
      },
      {
        name: 'Mirrors',
        tag: 'accessories:mirrors'
      },
      {
        name: 'Seashells',
        tag: 'accessories:seashells'
      },
      {
        name: 'Accessories - Miscellaneous',
        tag: 'accessories:accessories-miscellaneous'
      },
      {
        name: 'Clocks & alarms',
        tag: 'accessories:clocks-alarms'
      },
      {
        name: 'Jars',
        tag: 'accessories:jars'
      },
      {
        name: 'Figures & Figurines',
        tag: 'accessories:figures-figurines'
      },
      {
        name: 'Boxes',
        tag: 'accessories:boxes'
      },
      {
        name: 'Weapons',
        tag: 'accessories:weapons'
      },
      {
        name: 'Candlesticks',
        tag: 'accessories:candlesticks'
      },
      {
        name: 'Books',
        tag: 'accessories:books'
      },
      {
        name: 'Toys',
        tag: 'accessories:toys'
      },
      {
        name: 'Ashtrays',
        tag: 'accessories:ashtrays'
      },
      {
        name: 'Baskets',
        tag: 'accessories:baskets'
      },
      {
        name: 'Trophies',
        tag: 'accessories:trophies'
      },
      {
        name: 'Wastebaskets',
        tag: 'accessories:wastebaskets'
      },
      {
        name: 'Cleaning',
        tag: 'accessories:cleaning'
      },
      {
        name: 'Luggage and bags',
        tag: 'accessories:luggage'
      },
      {
        name: 'Patio Umbrellas',
        tag: 'accessories:patio-umbrellas'
      },
      {
        name: 'Vases & Cachepots',
        tag: 'accessories:vases'
      },
      {
        name: 'Plants',
        tag: 'accessories:plants'
      }
    ]
  },
  {
    name: 'Office',
    tag: 'office',
    pingpong: true,
    subcategories: [
      {
        name: 'Office desks',
        tag: 'office:office-desks'
      },
      {
        name: 'Archives',
        tag: 'office:archives'
      },
      {
        name: 'Books',
        tag: 'office:books'
      },
      {
        name: 'Maps',
        tag: 'office:maps'
      },
      {
        name: 'Posters',
        tag: 'office:posters'
      },
      {
        name: 'Lockers',
        tag: 'office:lockers'
      },
      {
        name: 'Office Electronics',
        tag: 'office:electronics'
      },
      {
        name: 'Office and Desk Dressing',
        tag: 'office:office-and-desk-dressing'
      },
      {
        name: 'Office Seating',
        tag: 'office:seating'
      },
      {
        name: 'Office Desks',
        tag: 'office:desks'
      },
      {
        name: 'Office Furniture',
        tag: 'office:furniture'
      },
      {
        name: 'Office Tables',
        tag: 'office:tables'
      }
    ]
  },
  {
    name: 'Party',
    tag: 'party',
    pingpong: false,
    subcategories: [
      {
        name: 'Christmas',
        tag: 'party:christmas'
      },
      {
        name: 'Halloween',
        tag: 'party:halloween'
      },
      {
        name: 'Easter',
        tag: 'party:easter'
      },
      {
        name: 'Carnival',
        tag: 'party:carnival'
      },
      {
        name: 'Party - Miscellaneous',
        tag: 'party:party-miscellaneous'
      },
      {
        name: 'New Year',
        tag: 'party:new-year'
      },
      {
        name: 'Birthday',
        tag: 'party-birthday'
      }
    ]
  },
  {
    name: 'Gym/Sports/Fitness',
    tag: 'gym-outdoor-fitness',
    pingpong: false,
    subcategories: [
      {
        name: 'Skates',
        tag: 'gym-outdoor-fitness:skates'
      },
      {
        name: 'Rollers',
        tag: 'gym-outdoor-fitness:rollers'
      },
      {
        name: 'Helmets',
        tag: 'gym-outdoor-fitness:helmets'
      },
      {
        name: 'Accessories',
        tag: 'gym-outdoor-fitness:accessories'
      },
      {
        name: 'Balls',
        tag: 'gym-outdoor-fitness:balls'
      },
      {
        name: 'Bikes',
        tag: 'gym-outdoor-fitness:bikes'
      },
      {
        name: 'Scooters',
        tag: 'gym-outdoor-fitness:scooters'
      },
      {
        name: 'Tennis',
        tag: 'gym-outdoor-fitness:tennis'
      },
      {
        name: 'Boxing',
        tag: 'gym-outdoor-fitness:boxing'
      },
      {
        name: 'Golf',
        tag: 'gym-outdoor-fitness:golf'
      },
      {
        name: 'Water sports',
        tag: 'gym-outdoor-fitness:water-sports'
      },
      {
        name: 'Bicycles',
        tag: 'gym-outdoor-fitness:bicycles'
      },
      {
        name: 'Balls',
        tag: 'gym-outdoor-fitness:balls'
      },
      {
        name: 'Miscellaneous',
        tag: 'gym-outdoor-fitness:miscellaneous'
      }
    ]
  },
  {
    name: 'Military',
    tag: 'military',
    pingpong: true,
    subcategories: [
      {
        name: 'Tools',
        tags: 'military:tools'
      },
      {
        name: 'Weapons',
        tags: 'military:weapons'
      },
      {
        name: 'Industrial furniture',
        tags: 'military:industrial-furniture'
      },
      {
        name: 'Trunks & bags',
        tags: 'military:trunks-bags'
      },
      {
        name: 'Wall maps & posters',
        tags: 'military:wall-maps-posters'
      },
      {
        name: 'Helmets & clothing',
        tags: 'military:helmets-clothing'
      },
      {
        name: 'Camouflage netting & burlap',
        tags: 'military:camouflage-netting-burlap'
      }
    ]
  },
  {
    name: 'Garden & camping',
    tag: 'garden-camping',
    pingpong: true,
    subcategories: [
      {
        name: 'Bird cages',
        tag: 'garden-camping:bird-cages'
      },
      {
        name: 'Benches',
        tag: 'garden-camping:benches'
      },
      {
        name: 'Loungers & transats',
        tag: 'garden-camping:loungers-transats'
      },
      {
        name: 'Outdoor lighting',
        tag: 'garden-camping:outdoor-lighting'
      },
      {
        name: 'Plant stands',
        tag: 'garden-camping:plant-stands'
      },
      {
        name: 'Artificial flowers and plants',
        tag: 'garden-camping:artificial-flowers-and-plants'
      },
      {
        name: 'Vases',
        tag: 'garden-camping:vases'
      },
      {
        name: 'Parasols & umbrellas',
        tag: 'garden-camping:parasols-umbrellas'
      },
      {
        name: 'Camping tools',
        tag: 'garden-camping:camping-tools'
      },
      {
        name: 'Camping furniture',
        tag: 'garden-camping:camping-furniture'
      },
      {
        name: 'Tents',
        tag: 'garden-camping:tents'
      },
      {
        name: 'Furniture',
        tag: 'garden-camping:furniture'
      }
    ]
  },
  {
    name: 'Electronics',
    tag: 'electronics',
    pingpong: true,
    subcategories: [
      {
        name: 'Appliances',
        tag: 'electronics:appliances'
      },
      {
        name: 'Electronics - Miscellaneous',
        tag: 'electronics:electronics-miscellaneous'
      },
      {
        name: 'Speakers',
        tag: 'electronics:speakers'
      },
      {
        name: 'Smartphones',
        tag: 'electronics:smartphones'
      },
      {
        name: 'Tablet',
        tag: 'electronics:tablet'
      },
      {
        name: 'Laptops',
        tag: 'electronics:laptops'
      },
      {
        name: 'Monitors',
        tag: 'electronics:monitors'
      },
      {
        name: 'Projectors',
        tag: 'electronics:projectors'
      },
      {
        name: 'Cameras',
        tag: 'electronics:cameras'
      },
      {
        name: 'Surveillance cameras',
        tag: 'electronics:sureveillance-cameras'
      },
      {
        name: 'Televisions',
        tag: 'electronics:televisions'
      },
      {
        name: 'Radios',
        tag: 'electronics:radios'
      },
      {
        name: 'Telephones',
        tag: 'electronics:telephones'
      },
      {
        name: 'Clocks',
        tag: 'electronics:clocks'
      },
      {
        name: 'Computers',
        tag: 'electronics:computers'
      }
    ]
  },
  {
    name: 'Desks',
    tag: 'desk',
    pingpong: true,
    subcategories: []
  },
  {
    name: 'Kids',
    tag: 'kids',
    pingpong: true,
    subcategories: [
      {
        name: 'Kids room furniture',
        tag: 'kids:kids-room-furniture'
      },
      {
        name: 'Toys',
        tag: 'kids:toys'
      },
      {
        name: 'Playstation',
        tag: 'kids:playstation'
      },
      {
        name: 'Videogames',
        tag: 'kids:videogames'
      },
      {
        name: 'Kids seating',
        tag: 'kids:kids-seating'
      }
    ]
  },
  {
    name: 'Music',
    tag: 'music',
    pingpong: true,
    subcategories: [
      {
        name: "CD's",
        tag: 'music:cds'
      },
      {
        name: "Vinyl's",
        tag: 'music:vinyls'
      },
      {
        name: 'Speakers',
        tag: 'music:speakers'
      },
      {
        name: 'Furniture',
        tag: 'music:furniture'
      },
      {
        name: 'Music - Instruments',
        tag: 'music:instruments'
      },
      {
        name: 'Music - Electronics',
        tag: 'music:electronics'
      }
    ]
  },
  {
    name: 'Series',
    tag: 'series',
    pingpong: true,
    subcategories: []
  },
  {
    name: 'Industrial furniture',
    tag: 'industrial-furniture',
    pingpong: true,
    subcategories: []
  },
  {
    name: 'Medical',
    tag: 'medical',
    pingpong: true,
    subcategories: [
      {
        name: 'Books',
        tag: 'medical:books'
      },
      {
        name: 'Affiches',
        tag: 'medical:affiches'
      },
      {
        name: 'Scientific case',
        tag: 'medical:scientific-case'
      },
      {
        name: 'Medical - furniture',
        tag: 'medical:furniture'
      },
      {
        name: 'Medical - supplies',
        tag: 'medical:supplies'
      },
      {
        name: 'Medical - tools',
        tag: 'medical:tools'
      }
    ]
  }
]
