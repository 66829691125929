import React, { createContext, useState, useEffect } from 'react'
import { useBreakpointValue } from '@chakra-ui/react'
import { useQuotesIndexedDBStorage } from '../hooks/index'
import UUID from 'pure-uuid'

export const QuoteContext = createContext()

function addMissingIds (quote) {
  if (quote == null) return
  if (quote.rows == null) quote.rows = []
  if (quote.headerRows == null) quote.headerRows = []
  quote.rows.forEach((r) => {
    if (r.id == null && !r.public_id) r.id = new UUID(4).format()
  })
  quote.headerRows.forEach((r) => {
    if (r.id == null) r.id = new UUID(4).format()
  })
  return quote
}

export function QuoteContextProvider (props) {
  const quoteId = props.match.params.quoteId
  const [quote, setQuote] = useState(null)
  const { dbInitialized, getByID, update } = useQuotesIndexedDBStorage()
  const [isPrint, setIsPrint] = useState(false)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [date, setDate] = useState(+new Date())
  const [showDiscountColumn, setShowDiscountColumn] = useState(quote ? quote.showDiscountColumn : false)
  const [showDiscountRow, setShowDiscountRow] = useState(quote ? quote.showDiscountRow : false)
  const [showTitleRow, setShowTitleRow] = useState(quote ? quote.showTitleRow : false)
  const [title, setTitle] = useState(quote ? quote.title : 'Quote')

  useEffect(() => {
    const beforePrintFn = () => setIsPrint(true)
    const afterPrintFn = () => setIsPrint(false)
    window.addEventListener('beforeprint', beforePrintFn)
    window.addEventListener('afterprint', afterPrintFn)
    return () => {
      window.removeEventListener('beforeprint', beforePrintFn)
      window.removeEventListener('afterprint', afterPrintFn)
    }
  }, [])

  useEffect(() => {
    if (quoteId != null && dbInitialized) {
      // console.log('getByID quoteId context.js')
      getByID(+quoteId).then(addMissingIds).then(setQuote)
    }
  }, [quoteId, dbInitialized, getByID])

  const updateAndReload = async (q = quote) => {
    // console.log('updateAndReload context.js')
    // const updatedQuote =
    q.totalExc = q.rows.reduce((acc, r) => acc + r.total, 0)
    await update(q)
    // getByID(+quoteId).then(setQuote)
    const updatedQuote = await getByID(+quoteId)
    setQuote(updatedQuote)
  }

  const addRow = () => {
    if (quote != null) {
      quote.rows.push({ type: 'item-manual', id: new UUID(4).format() })
      // console.log('updateAndReload addRow context.js')
      updateAndReload(quote)
    }
  }

  const addHeaderRow = () => {
    if (quote != null) {
      quote.headerRows = quote.headerRows || []
      quote.headerRows.push({ type: 'header', id: new UUID(4).format() })
      // console.log('updateAndReload addHeaderRow context.js')
      updateAndReload(quote)
    }
  }

  const deleteHeaderRow = (row) => {
    if (quote != null) {
      quote.headerRows = quote.headerRows.filter((r) => r !== row)
      // console.log('updateAndReload deleteHeaderRow context.js')
      updateAndReload(quote)
    }
  }

  const addItemsFromWishlist = () => {
    if (quote != null && props.wishlist != null) {
      const toAdd = props.wishlist.filter(
        (w) => !quote.rows.find((r) => r.public_id === w.public_id)
      )
      quote.rows.push(...toAdd.map((r) => ({ ...r, type: 'item' })))
      // console.log('updateAndReload addItemsFromWishlist context.js')
      updateAndReload(quote)
    }
  }

  const context = {
    quote,
    setQuote,
    updateAndReload,
    addRow,
    addHeaderRow,
    deleteHeaderRow,
    addItemsFromWishlist,
    date,
    setDate,
    isPrint,
    isMobile,
    showDiscountColumn,
    setShowDiscountColumn,
    showDiscountRow,
    setShowDiscountRow,
    showTitleRow,
    setShowTitleRow,
    title,
    setTitle
  }

  return (
    <QuoteContext.Provider value={context}>
      {quote != null && props.children}
    </QuoteContext.Provider>
  )
}

export default QuoteContext
