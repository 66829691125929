import React, { useState, useEffect, useRef, Fragment } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import {
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Grid,
  GridItem
} from '@chakra-ui/react'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useQuotesIndexedDBStorage } from './hooks/index'

export function QuoteList (props) {
  const history = useHistory()
  const {
    getSharedWishListObj
  } = props
  const { onOpen, isOpen, onClose } = useDisclosure()
  const nameRef = useRef()
  const sharedWishlistIdRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [quotes, setQuotes] = useState([])
  const [name, setName] = useState('')
  const { dbInitialized, add, getAll, deleteByID } = useQuotesIndexedDBStorage()

  useEffect(() => {
    if (dbInitialized) getAll().then(setQuotes)
  }, [dbInitialized, getAll])

  const handleSubmit = async (e) => {
    if (e != null) e.preventDefault()
    if (e.key === 'Enter') await handleCreate()
  }

  const handleCreate = async () => {
    try {
      setIsLoading(true)
      const wishlistUrlOrId = sharedWishlistIdRef.current.value || ''
      const wishlistId = wishlistUrlOrId.split('/').pop() || null
      let wishlist = null
      if (wishlistId) wishlist = await getSharedWishListObj(wishlistId)
      const data = {
        name: nameRef.current.value || '',
        wishlist,
        rows: wishlist ? wishlist.wishlist : [],
        createdAt: +new Date(),
        showDiscountColumn: false
      }
      await add(data)
      getAll().then(setQuotes)
    } finally {
      onClose()
      setIsLoading(false)
    }
  }

  const deleteQuote = async (quote) => {
    try {
      setIsLoading(true)
      await deleteByID(quote.id)
      getAll().then(setQuotes)
    } finally {
      setIsLoading(false)
    }
  }

  const openQuote = (quote) => {
    history.push(`/quotes/${quote.id}`)
  }

  return (
    <Box maxWidth='800px' marginX='auto'>
      <Button
        onClick={onOpen}
        colorScheme='blue'
        size='lg'
        mt='1rem'
        ml='1rem'
      >
        Create a quote
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder='Quote name'
              ref={nameRef}
              onChange={e => setName(e.target.value)}
              onKeyUp={handleSubmit}
            />
            <Input
              mt='1rem'
              ref={sharedWishlistIdRef}
              placeholder='Create from shared wishlist (optional)'
              onKeyUp={handleSubmit}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCreate} isLoading={isLoading} isDisabled={isLoading || name === ''} loadingText='Creating Quote'>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Grid templateColumns='auto auto min-content' gap='3' className='tw-p-8' alignItems='center' maxWidth='600px'>
        <GridItem colSpan='1' display='flex' fontWeight='bold'>
          Name
        </GridItem>
        <GridItem colSpan='1' display='flex' fontWeight='bold'>
          Created At
        </GridItem>
        <GridItem colSpan='1' display='flex' />
        {quotes.map(quote => (
          <Fragment key={quote.id}>
            <GridItem colSpan='1' display='flex' cursor='pointer' onClick={() => openQuote(quote)}>
              {quote.name}
            </GridItem>
            <GridItem colSpan='1' display='flex' cursor='pointer' onClick={() => openQuote(quote)}>
              {new Date(quote.createdAt).toLocaleDateString()} {new Date(quote.createdAt).toLocaleTimeString()}
            </GridItem>
            <GridItem colSpan='1' display='flex'>
              <RiDeleteBin6Line className='tw-print:tw-hidden' onClick={() => deleteQuote(quote)} cursor='pointer' />
            </GridItem>
          </Fragment>
        ))}
      </Grid>

    </Box>
  )
}

export default withRouter(QuoteList)
