import React, { useContext } from 'react'
import { GridItem, Input, Text } from '@chakra-ui/react'
import QuoteContext from './quote-context'

const QuoteDate = ({ showDiscountColumn }) => {
  const { quote, updateAndReload } = useContext(QuoteContext)

  const handleDateChange = (e) => {
    // console.log('handleDateChange', e.target.value)
    quote.date = e.target.value
    updateAndReload()
  }

  if (quote == null) return <></>

  const {
    isRentalWeeksPerItem = false
  } = quote

  let colspan = 4
  if (showDiscountColumn) colspan += 2
  if (isRentalWeeksPerItem) colspan += 1

  return (
    <>
      <GridItem colSpan={1}>
        <Text fontSize='sm' as='b'>
          Date:
        </Text>
      </GridItem>
      <GridItem
        colSpan={colspan}
        display='flex'
        alignItems='center'
      >
        <Input
          className='tw-print:tw-p-0'
          variant='unstyled'
          placeholder='Select Date'
          size='md'
          type='date'
          width='100px'
          value={quote.date ?? new Date()}
          onChange={handleDateChange}
        />
      </GridItem>
    </>
  )
}

export default QuoteDate
