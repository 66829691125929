import axios from 'axios'
import _ from 'lodash'
import config from './config/default'
/* globals getComputedStyle */

function artCollectionURLForTag (cloudId, tag) {
  return `https://res.cloudinary.com/${cloudId}/image/list/${tag}.json`
}

function imgLinkFromPublicId (cloudId, publicId, width) {
  return `https://res.cloudinary.com/${cloudId}/image/upload/c_limit,dpr_auto${
    width ? `,w_${width}` : ''
  }/v1/${publicId}`
}

function getClosestInArr (needle, haystack) {
  return haystack.reduce((prev, curr) =>
    Math.abs(curr - needle) < Math.abs(prev - needle) ? curr : prev
  )
}

function convertRemToPixels (rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

function convertPixelsToRem (pixels) {
  return (
    pixels / parseFloat(getComputedStyle(document.documentElement).fontSize)
  )
}

const getPriceAndRest = (data) => {
  let price = _.get(data, config.gallery.itemPricePath)
  let rest = ''
  if (price) {
    const matches = price.match(/^(\d+)(.*)/)
    if (matches && matches.length === 3 && !isNaN(+matches[1])) {
      price = matches[1]
      rest = matches[2]
    }
  }
  return { price, rest }
}

export const getTagResources = async (tag, cloudId) => {
  const response = await axios.get(artCollectionURLForTag(cloudId, tag))
  if (response && response.data.resources) {
    return response.data.resources
  }
  return null
}

export {
  getPriceAndRest,
  artCollectionURLForTag,
  imgLinkFromPublicId,
  getClosestInArr,
  convertRemToPixels,
  convertPixelsToRem
}

// only works in the browser
export const resizeImg = async (
  srcStr,
  MAX_WIDTH = 300,
  MAX_HEIGHT = 300,
  type = 'image/png'
) => {
  return await new Promise((resolve) => {
    const img = document.createElement('img')
    img.onload = function (event) {
      let width = img.width
      let height = img.height

      // Change the resizing logic
      if (width > height) {
        if (width > MAX_WIDTH) {
          height = height * (MAX_WIDTH / width)
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width = width * (MAX_HEIGHT / height)
          height = MAX_HEIGHT
        }
      }
      // Dynamically create a canvas element
      const canvas = document.createElement('canvas')
      // var canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = width
      canvas.height = height
      // Actual resizing
      if (ctx != null) ctx.drawImage(img, 0, 0, width, height)
      // Show resized image in preview element
      const dataurl = canvas.toDataURL(type)
      setTimeout(() => img.remove(), 300)
      setTimeout(() => canvas.remove(), 300)
      resolve(dataurl)
    }
    img.src = srcStr
  })
}
