import React, { useContext, useEffect, useState } from 'react'
import {
  GridItem,
  Textarea,
  Box
} from '@chakra-ui/react'
import QuoteContext from './quote-context'
import { RiDeleteBin6Line } from 'react-icons/ri'

const RESIZE = {
  NONE: 'none',
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
}

let timeoutId = null
let timeoutId2 = null
const GridHeaderRow = ({ headerRow, showDiscountColumn = false }) => {
  const { updateAndReload, deleteHeaderRow, quote } = useContext(QuoteContext)
  const [value, _setValue] = useState(headerRow.value || '')
  const [height, _setHeight] = useState(headerRow.height || 40)
  const [resize, setResize] = useState(RESIZE.VERTICAL)
  const ref = React.useRef(null)

  useEffect(() => {
    const eventHandler = () => setResize(RESIZE.NONE)
    const eventHandler2 = () => setResize(RESIZE.VERTICAL)
    window.addEventListener('beforeprint', eventHandler)
    window.addEventListener('afterprint', eventHandler2)
    return () => {
      window.removeEventListener('beforeprint', eventHandler)
      window.removeEventListener('afterprint', eventHandler2)
    }
  }, [])

  const setValue = (value) => {
    headerRow.value = value
    _setValue(value)
    if (timeoutId != null) clearTimeout(timeoutId)
    timeoutId = setTimeout(updateAndReload, 500)
  }

  const setHeight = (value) => {
    headerRow.height = value
    _setHeight(value)
    if (timeoutId2 != null) clearTimeout(timeoutId2)
    timeoutId2 = setTimeout(updateAndReload, 500)
  }

  const getTextareaHeight = () => {
    window.removeEventListener('mouseup', getTextareaHeight)
    if (ref.current && ref.current.offsetHeight !== height) setHeight(ref.current.offsetHeight)
  }

  const {
    isRentalWeeksPerItem = false
  } = quote || {}

  let colspan = 5
  if (showDiscountColumn) colspan += 2
  if (isRentalWeeksPerItem) colspan += 1

  return (
    <GridItem colSpan={colspan} display='flex' alignItems='center' justifyContent='start'>
      <Textarea
        ref={ref}
        variant='unstyled'
        padding='0'
        margin='0' border='none'
        resize={resize}
        minH='unset'
        height={height}
        value={value || ''}
        onChange={e => setValue(e.target.value)}
        onClick={() => window.addEventListener('mouseup', getTextareaHeight)}
      />
      <Box ml='1' className='tw-print:tw-hidden'>
        <RiDeleteBin6Line className='tw-print:tw-hidden' onClick={() => deleteHeaderRow(headerRow)} cursor='pointer' />
      </Box>
    </GridItem>
  )
}

export default GridHeaderRow
