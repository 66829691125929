import React, { useContext, useEffect, useState } from 'react'
import {
  GridItem
} from '@chakra-ui/react'
import QuoteContext from './quote-context'

let timeoutId = null
const QuoteTotalRows = ({ showDiscountColumn }) => {
  const { quote, update } = useContext(QuoteContext)
  const [vat, setVat] = useState(quote && quote.vat && !isNaN(quote.vat) ? quote.vat : 0)
  const [totalInc, setTotalInc] = useState(0)
  const [subtotal, setSubtotal] = useState(quote && quote.totalExc ? quote.totalExc - (quote.discount || 0) : 0)
  const [vatPercentage, setVatPercentage] = useState(quote && quote.vatPercentage ? quote.vatPercentage : 21)
  const [discount, setDiscount] = useState(quote && quote.discount ? quote.discount : 0)

  useEffect(() => {
    if (quote) {
      quote.vat = vat
      quote.vatPercentage = vatPercentage
    }
  }, [vat, vatPercentage]) // eslint-disable-line

  useEffect(() => {
    if (quote.showDiscountRow) {
      setSubtotal(quote.totalExc - (discount || 0))
    } else {
      setSubtotal(quote.totalExc)
    }
  }, [quote.totalExc, discount, quote.showDiscountRow])

  useEffect(() => {
    if (quote.vatPercentage !== vatPercentage || quote.discount !== discount) {
      quote.vatPercentage = vatPercentage
      quote.discount = discount
      if (timeoutId) clearTimeout(timeoutId)
      timeoutId = setTimeout(update, 500)
    }
  }, [vatPercentage, quote.vatPercentage, discount, quote.discount, update])

  useEffect(() => {
    setVat(+(subtotal * (vatPercentage / 100)))
  }, [subtotal, vatPercentage])

  useEffect(() => {
    setTotalInc(+(subtotal + vat))
  }, [vat, subtotal])

  if (quote == null) return (<></>)

  const {
    isRentalWeeksPerItem = false
  } = quote

  let colSpan = 4
  if (showDiscountColumn) colSpan += 2
  if (isRentalWeeksPerItem) colSpan += 1

  return (
    <>
      <GridItem colSpan={colSpan} fontSize='sm' fontWeight='bold' display='flex' justifyContent='flex-end'>
        Total:
      </GridItem>
      <GridItem colSpan={1} display='flex' fontSize='sm' fontWeight='bold' justifyContent='flex-end'>
        {(quote.totalExc ? quote.totalExc.toFixed(2) : 0) + '€'}
      </GridItem>

      {quote.showDiscountRow &&
        <>
          <GridItem colSpan={colSpan} display='flex' fontSize='sm' fontWeight='bold' justifyContent='flex-end'>
            Discount:
          </GridItem>
          <GridItem colSpan='1' display='flex' fontSize='sm' fontWeight='bold' justifyContent='flex-end'>
            <input
              type='number'
              onChange={(e) => setDiscount(+e.target.value)}
              value={discount} min='0'
              step='0.01'
              style={{ width: `${`${discount}`.length}ch`, textAlign: 'right' }}
            />€
          </GridItem>

          <GridItem colSpan={colSpan} display='flex' fontSize='sm' fontWeight='bold' justifyContent='flex-end'>
            Subtotal:
          </GridItem>
          <GridItem colSpan='1' display='flex' fontSize='sm' fontWeight='bold' justifyContent='flex-end'>
            {subtotal ? subtotal.toFixed(2) : 0}€
          </GridItem>
        </>}

      <GridItem colSpan={colSpan} fontSize='sm' fontWeight='bold' display='flex' justifyContent='flex-end'>
        VAT(<input type='number' onChange={(e) => setVatPercentage(+e.target.value)} value={vatPercentage} min='0' style={{ width: `${`${vatPercentage}`.length}ch` }} />%):
      </GridItem>
      <GridItem colSpan='1' display='flex' fontSize='sm' fontWeight='bold' justifyContent='flex-end'>
        {(vat ? vat.toFixed(2) : '0') + '€'}
      </GridItem>

      <GridItem colSpan={colSpan} fontSize='sm' fontWeight='bold' display='flex' justifyContent='flex-end'>
        Total Inclusive VAT:
      </GridItem>

      <GridItem colSpan='1' display='flex' fontSize='sm' fontWeight='bold' justifyContent='flex-end'>
        {(totalInc ? totalInc.toFixed(2) : '0') + '€'}
      </GridItem>
    </>
  )
}

export default QuoteTotalRows
