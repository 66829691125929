import './app.scss'
import 'react-toastify/dist/ReactToastify.css'
import React, { Component, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import * as _ from 'lodash'
import config from './config/default'
import Gallery from './gallery'
import Item from './item'
import Navbar2 from './navbar'
import Sidebar from './sidebar'
import Contact from './contact'
import Favicon from 'react-favicon'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
// import Button from 'react-bootstrap/Button'
import JSONBin from './jsonbin'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { getTagResources } from './utils'
import Quote from './quote/index'
import QuoteList from './quote-list'
import {
  ChakraProvider, extendTheme, Flex
} from '@chakra-ui/react'

toast.configure()

const theme = extendTheme({
  colors: {
    brand: '#0079bf',
    success: '#70b500',
    danger: '#eb5a46',
    info: '#ff9f1a',
    warning: '#f2d600',
    darkblue: '#eae6ff',
    lightblue: '#f2faf9',
    performance: '#0079bf',
    bug: '#eb5a46',
    feature: '#61bd4f',
    information: '#ff9f1a'
  }
})

/* global localStorage */
class App extends Component {
  constructor (props) {
    super(props)
    const wishlist = JSON.parse(localStorage.getItem('wishlist')) || []
    this.state = {
      categories: config.categories,
      wishlist,
      modalShow: false
    }
  }

  addToWishlist = item => {
    const wishlist = this.state.wishlist
    const itemInArray = this.state.wishlist.find(i => i.public_id === item.public_id)
    if (!itemInArray) wishlist.push(item)
    localStorage.setItem('wishlist', JSON.stringify(wishlist))
    this.setState({ wishlist })
  }

  removeFromWishlist = item => {
    const wishlist = this.state.wishlist.filter(d => d.public_id !== item.public_id)
    localStorage.setItem('wishlist', JSON.stringify(wishlist))
    this.setState({ wishlist })
  }

  shareWishList = async () => {
    const response = await JSONBin.saveWishlist({ wishlist: this.state.wishlist })
    // console.log(response)
    this.setState({ modalShow: true, wishlistShareUrl: `${window.location.origin}/shared-wishlist/${response.metadata.id}`, wishlistId: response.id })
  }

  setWishlistName = async (id, name) => {
    await JSONBin.updateWishlist(id, { name })
  }

  getSharedWishList = async (id) => {
    const response = await this.getSharedWishListObj(id)
    return response
  }

  getSharedWishListObj = async (id) => {
    return await JSONBin.getWishlist(id)
  }

  setPictureWidth = width => {
    if (!this.state.pictureWidth || this.state.pictureWidth !== width) {
      this.setState({ pictureWidth: width })
    }
  }

  async fetchallCategoryNumbers (categories) {
    const promises = []
    for (const category of categories) {
      const promise = getTagResources(category.tag, config.cloudId).then(resources => {
        category.nbItems = 0
        if (resources) category.nbItems = resources.length
        if (category.nbItems && !_.isEmpty(category.subcategories)) this.fetchallCategoryNumbers(category.subcategories)
        return categories
      }).catch(e => {
        // console.error(e)
      })
      promises.push(promise)
    }
    await Promise.all(promises)
    return categories
  }

  componentDidMount () {
    document.title = config.title
    this.fetchallCategoryNumbers(this.state.categories).then(categories => this.setState({ categories }))
  }

  render () {
    const {
      pictureWidth,
      categories,
      wishlist,
      modalShow,
      wishlistShareUrl,
      wishlistId
    } = this.state
    return (
      <ChakraProvider theme={theme}>
        <div className='main container-fluid p0'>
          <MyVerticallyCenteredModal
            show={modalShow}
            value={wishlistShareUrl}
            onHide={() => this.setState({ modalShow: false })}
            setWishlistName={(name) => this.setWishlistName(wishlistId, name)}
          />
          <Favicon url={config.favicon} />
          <Router>
            <Switch>
              <Route path='/404'>
                <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                <div className='row'>
                  {/* {navWrapper} */}
                  <div className='content col-md-9'>
                    <h1>Not Found</h1>
                  </div>
                </div>
              </Route>
              <Route
                path='/categories/:tag'
                render={routeProps =>
                  <>
                    <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                    <Flex px='1rem' justifyContent='space-between' alignItems='start'>
                      <Sidebar categories={categories} className='tw-self-start tw-sticky tw-top-0' />
                      <Gallery
                        key={routeProps.match.params.tag} {...routeProps} {...{
                          wishlist,
                          handleAddToWishlist: this.addToWishlist,
                          handleRemoveFromWishlist: this.removeFromWishlist
                        }}
                      />
                    </Flex>
                  </>}
              />
              <Redirect exact from='/categories' to='/categories/seating' />
              <Route path='/contact'>
                <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                <div className='row'>
                  <div className='content col-md-12 p0'>
                    <Contact />
                  </div>
                </div>
              </Route>
              <Route
                path='/wishlist/:item_id' render={routeProps =>
                  <>
                    <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                    <Item
                      {...routeProps} {...{
                        isWishlist: true,
                        wishlist,
                        handleAddToWishlist: this.addToWishlist,
                        handleRemoveFromWishlist: this.removeFromWishlist
                      }}
                    />
                  </>}
              />
              <Route
                path='/wishlist' render={routeProps =>
                  <>
                    <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                    <div className='row'>
                      <div className='content col-md-12 p0'>
                        <Gallery
                          {...routeProps} {...{
                            showWishlist: true,
                            wishlist,
                            handleAddToWishlist: this.addToWishlist,
                            handleRemoveFromWishlist: this.removeFromWishlist,
                            setPictureWidth: this.setPictureWidth,
                            shareWishList: this.shareWishList
                          }}
                        />
                      </div>
                    </div>
                  </>}
              />

              <Route
                path={['/quotes/:quoteId']} render={routeProps =>
                  <>
                    <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                    <div className='row'>
                      <div className='content col-md-12 p0'>
                        <Quote
                          {...routeProps} {...{
                            wishlist,
                            showWishlist: false,
                            handleAddToWishlist: this.addToWishlist,
                            handleRemoveFromWishlist: this.removeFromWishlist,
                            setPictureWidth: this.setPictureWidth,
                            getSharedWishList: this.getSharedWishList,
                            getSharedWishListObj: this.getSharedWishListObj,
                            shareWishList: this.shareWishList
                          }}
                        />
                      </div>
                    </div>
                  </>}
              />

              <Route
                path='/quotes' render={routeProps =>
                  <>
                    <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                    <div className='row'>
                      <div className='content col-md-12 p0'>
                        <QuoteList
                          {...routeProps} {...{
                            wishlist,
                            showWishlist: false,
                            handleAddToWishlist: this.addToWishlist,
                            handleRemoveFromWishlist: this.removeFromWishlist,
                            setPictureWidth: this.setPictureWidth,
                            getSharedWishList: this.getSharedWishList,
                            getSharedWishListObj: this.getSharedWishListObj,
                            shareWishList: this.shareWishList
                          }}
                        />
                      </div>
                    </div>
                  </>}
              />

              <Route
                path='/shared-wishlist/:wishlistId' render={routeProps =>
                  <>
                    <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                    <div className='row'>
                      <div className='content col-md-12 p0'>
                        <Gallery
                          {...routeProps} {...{
                            wishlist,
                            showWishlist: false,
                            handleAddToWishlist: this.addToWishlist,
                            handleRemoveFromWishlist: this.removeFromWishlist,
                            setPictureWidth: this.setPictureWidth,
                            getSharedWishList: this.getSharedWishList,
                            getSharedWishListObj: this.getSharedWishListObj,
                            shareWishList: this.shareWishList
                          }}
                        />
                      </div>
                    </div>
                  </>}
              />
              <Route
                path='/:tag/:item_id'
                render={routeProps =>
                  <>
                    <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                    <Item
                      {...routeProps} {...{
                        wishlist,
                        handleAddToWishlist: this.addToWishlist,
                        handleRemoveFromWishlist: this.removeFromWishlist,
                        setPictureWidth: this.setPictureWidth
                      }}
                    />
                  </>}
              />
              <Route
                path='/' render={routeProps =>
                  <>
                    <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                    <Flex px='1rem' justifyContent='space-between' alignItems='start'>
                      <Sidebar categories={categories} className='tw-self-start tw-sticky tw-top-0' />
                      <Gallery
                        key='home' {...routeProps}
                        {...{
                          isHome: true,
                          tags: config.categories.map(c => c.tag),
                          filter: (res) => !res.public_id.includes('lamps-floor') && !res.public_id.includes('Coat-rack'),
                          wishlist,
                          handleAddToWishlist: this.addToWishlist,
                          handleRemoveFromWishlist: this.removeFromWishlist,
                          setPictureWidth: this.setPictureWidth
                        }}
                      />
                    </Flex>
                  </>}
              />
            </Switch>
          </Router>
        </div>
      </ChakraProvider>
    )
  }
}

export default App

const MyVerticallyCenteredModal = (props) => {
  const [name, setName] = useState('')
  const [copied, setCopied] = useState(false)

  const {
    setWishlistName,
    onHide,
    value
  } = props

  const setWishlistNameLocal = () => {
    if (!_.isEmpty(name) && setWishlistName) setWishlistName(name)
  }

  const hide = () => {
    setWishlistName()
    setName('')
    setCopied(false)
    if (onHide) onHide()
  }

  const propsToPass = {}
  Object.keys(props).filter(k => k !== 'setWishlistName').forEach((key, i) => (propsToPass[key] = props[key]))

  return (
    <Modal
      {...propsToPass}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='model-share'
      onHide={hide}
    >
      <Modal.Header className='flex justify-center flex--column t-c align-center'>
        <Modal.Title id='contained-modal-title-vcenter'>
          Share this wishlist
        </Modal.Title>
        <div>You can save and share as many lists as you like. Each list has a URL</div>
      </Modal.Header>
      <Modal.Body>
        <input className='ml10' type='text' name='name' placeholder='My title' onKeyUp={(e) => setName(e.target.value)} />
        <CopyToClipboard
          text={value} onCopy={() => {
            toast.success('Link copied', {
              autoClose: 2500,
              style: { fontFamily: 'GravurCondensed !important' }
            })
            setWishlistNameLocal()
            setCopied(true)
            hide()
          }}
        >
          <div className='share-link'>
            <input type='text' className='share-pen-url' defaultValue={value} />
            <button className={'share-copy-link icofont-before ' + (copied ? 'icofont-check' : '')}>Copy Link</button>
          </div>
        </CopyToClipboard>

      </Modal.Body>
      {/* <Modal.Footer>
          <Button variant='dark' onClick={this.props.onHide}>Close</Button>
        </Modal.Footer> */}
    </Modal>
  )
}
